<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" sm="2">
        <conversations
          v-if="showConversations"
          class="fixed-top"
          :items="items"
          :activeItem="activeItem"
          @selectConversation="selectConversationHandler"
          @updateConversations="updateConversations"
        />
      </v-col>
      <v-col cols="12" :sm="chatColumnNumber">
        <chat
          v-if="showChat"
          :isAdmin="isAdmin"
          :conversationId="conversationId"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
const conversations = () => import("../components/chat/TheConversations");
const chat = () => import("../components/chat/TheChat");

export default {
  name: "Support",
  components: {
    conversations,
    chat,
  },
  data() {
    return {
      showConversations: false,
      showChat: false,
      conversationId: 1,
      isAdmin: false,
      items: [],
      activeItem: null,
    };
  },
  computed: {
    chatColumnNumber() {
      return this.isAdmin ? 10 : 12;
    },
  },
  created() {
    if (this.$route.name === "adminChat") {
      this.isAdmin = true;
      this.showConversations = true;
      this.fetchConversations();
    } else {
      this.showChat = true;
    }
  },
  methods: {
    updateConversations({ message: lastMessage, id, user }) {
      this.items = this.items.map((i) => {
        return +i.id === +id ? { ...i, lastMessage, user } : i;
      });

      // this.items = conversation
    },

    selectConversationHandler(conversations) {
      this.$router.replace({ query: { user: conversations.user.username } });
      this.activeItem = conversations.id;
      this.conversationId = conversations.id;
      this.showChat = true;
    },
    async fetchConversations() {
      try {
        const response = await this.$axios.get("chat/convs/");
        if (response.status === 200) {
          this.items = response.data.conversations;
          const user = this.items.find(
            (item) => item.user.username === this.$route.query.user
          );
          const pathIdUser = !user ? this.items[0] : user;
          this.selectConversationHandler(pathIdUser);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.fixed-top {
  position: sticky;
  position: -webkit-sticky;
  /* for Safari */
  top: 0;
  z-index: 2;
}
</style>
